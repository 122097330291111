import styled from "@emotion/styled"

export const StyledMenu = styled.nav`
  display:flex;
  width: 60vw;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: static;
  
  // TODO fix weird jank when resizing
  // transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
  
  background: transparent;
  
  
  
  transition: transform 0.2s ease-in-out;
  
  width: 100%;
  z-index: 1000;


a {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: color 0.3s linear;


  &:hover {
    color: darkblue;
  }
}


@media (max-width: 768px) {
  position: absolute;
  top 0;
  left: 0;
  background: #f6f6f6;
  border-bottom: 2px solid darkblue;
  height: 70vh;
  flex-direction: column;
  justify-content: space-evenly;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
  text-align: center;
  font-size: 2rem;

}
`
