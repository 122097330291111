import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { css } from "@emotion/core"

import logo from "../../images/logo.svg"

import Burger from "./burger/Burger"
import Menu from "./menu/Menu"

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  return (
    <header>
      <div
        css={css`
          display: flex;
          margin: 0 auto;
          margin-top: 1.5em;
          justify-content: center;
          align-items: center;
          max-width: 800px;
        `}
      >
        <Link
          to="/"
          css={css`
            margin-right: 50px;
            @media (max-width: 768px) {
              position: absolute;
              top: 2%;
              left: 2rem;
            }
          `}
        >
          <img src={logo} alt="Hedgecutters Ottawa Logo" />
        </Link>
        <Burger open={open} setOpen={setOpen} />
        <Menu css={css``} open={open} setOpen={setOpen} />

        {/* <div className="nav-items">
          <Link className="nav-link" to="/#about">
            about
          </Link>
          <Link className="nav-link" to="/#services">
            services
          </Link>

          <Link className="nav-link" to="/#pricing">
            pricing
          </Link>

          <Link className="nav-link" to="/contact">
            contact
          </Link>

          <a href="tel:6132900059" className="phone-btn">
            <div>
              <i className="fas fa-phone" /> &nbsp;613-290-0059
            </div>
          </a>
        </div> */}
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
