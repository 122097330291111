import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div className="footer-content">
      <ul className="footer-nav">
        <li>
          <Link to="/">home</Link>
        </li>
        <li>
          <Link to="/#about">about</Link>
        </li>
        <li>
          <Link to="/#services">services</Link>
        </li>
        <li>
          <Link to="/#pricing">pricing</Link>
        </li>
        <li>
          <Link to="/contact">contact</Link>
        </li>
      </ul>
      <ul className="footer-contact">
        <li>
          <a href="tel:6132900059">
            <i className="fas fa-phone" />
            &nbsp;613-290-0059
          </a>
        </li>
        <li>
          <a href="mailto:hedgecuttersottawa@gmail.com">
            <i className="fas fa-envelope" />
            &nbsp;hedgecuttersottawa@gmail.com
          </a>
        </li>
        <li>
          © <a href="http://www.hedgecuttersottawa.com">Hedgecutters Ottawa</a>
          &nbsp;{new Date().getFullYear()}
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
