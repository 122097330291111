import React from "react"
import { css } from "@emotion/core"
import { StyledMenu } from "./Menu.styled"

import { bool } from "prop-types"
import { Link } from "gatsby"

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <Link className="nav-link" to="/#about">
        about
      </Link>
      <Link className="nav-link" to="/#services">
        services
      </Link>

      <Link className="nav-link" to="/#pricing">
        pricing
      </Link>

      <Link className="nav-link" to="/contact">
        contact
      </Link>

      <a
        href="tel:6132900059"
        css={css`
          cursor: pointer;
          border: 4px solid #f0e7f6;
          background: #f6f6f6;
          border-radius: 50px;
          padding: 10px;
          margin-left: 20px;
          color: #600796 !important;
          a {
            padding: 0 10px;
            color: #600796 !important;
          }
        `}
      >
        <div>
          <i className="fas fa-phone" /> &nbsp;613-290-0059
        </div>
      </a>
    </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}
export default Menu
